.dashboard {
    .date-range {
        .card-content {
            padding: 5px 20px;

            .ant-picker-range {
                border: none;
            }

            .date-range {
                padding-top: 7px;

                .transaction-data-mode-switch {
                    margin-bottom: 0px;
                }
            }
        }
    }

    .Dashboard-card {
        .card-stats {
            .card-header {
                .material-icons {
                    width: 56px;
                    height: 56px;
                    text-align: center;
                    line-height: 50px;
                }
            }
            .skeleton{
                // padding-top: 40px;
            }
        }
    }.dashboard-chart {
        .card {
            .card-content {
                padding: 15px 20px;
            }
        }
    
        .footer-rechart {
            display: flex;
            align-items: center;
            color: grey;
            padding-left: 22px !important;

            .material-icons {
                color: rgb(229, 149, 19);
                font-size: 18px;
            }
        }

        .card-header-icon {
            background: linear-gradient(60deg, #512D6D, #441b5d);
            position: absolute;
        }

        .tab-container {
            display: flex;
            flex-wrap: wrap;

            .btn-group-sm {
                .btn {
                    font-size: 13px;
                    font-family: 'Montserrat';
                    text-transform: none;
                    width: 76px;
                    display: flex;
                    justify-content: center;
                }
            }

            .tabs {
                background: #1D617F;
                color: white;
            }

            .tabs:hover {
                background: #70bdd4;
            }

            .tabshover {
                background: #2E93B2;
            }
        }

        .dashbaord-chart-containt {
            padding: 0px 20px;

            .tabs-main-container {
                word-break: break-all;
            }

            .tabs-containt {
                display: flex;

                .revenue {
                    height: 15px;
                    width: 15px;
                    background-color: #006272;
                    padding-left: 15px;
                    margin-right: 5px;
                }

                .source {
                    height: 15px;
                    width: 15px;
                    background-color: #ff9e1b;
                    padding-left: 15px;
                    margin-right: 5px;
                }

                .creative {
                    height: 15px;
                    width: 15px;
                    background-color: #d50032;
                    padding-left: 15px;
                    margin-right: 5px;
                }

                .conversions {
                    height: 15px;
                    width: 15px;
                    background-color: #512d6d;
                    padding-left: 15px;
                    margin-right: 5px;
                }

                .zipcode {
                    height: 15px;
                    width: 15px;
                    background-color: #0093b2;
                    padding-left: 15px;
                    margin-right: 5px;
                }

                .icons-containt {
                    .tooltip {
                        cursor: pointer;
                        position: relative;
                        font-size: 12px;
                        line-height: 0;
                        vertical-align: baseline;
                    }
                }

            }
        }

        .chart-header {
            position: absolute;
        }

        .line-chart-container {
            margin-top: 50px;

            .recharts-tooltip-wrapper {
                border: 0.1px solid rgb(181, 179, 174);
                background: white;
                padding: 10px 10px 0px 10px;
                outline: 0;
            }

            .custom-tooltip {
                .date {
                    color: #000;
                    margin-bottom: 3px;
                }

                .revenue {
                    color: #006272;
                    margin-bottom: 5px;
                }

                .source {
                    color: #ff9e1b;
                    margin-bottom: 5px;
                }

                .creative {
                    color: #d50032;
                    margin-bottom: 5px;

                }

                .conversions {
                    color: #512d6d;
                    margin-bottom: 5px;
                }

                .zipcode {
                    color: #0093b2;
                    margin-bottom: 5px;
                }
            }

            .charts {
                position: relative;
            }

            .recharts-surface {
                font-family: 'Montserrat';
            }

            .rechart-text {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: grey;
                font-size: 20px;
                font-weight: 400;
                text-align: center;
            }
        }
    }
}