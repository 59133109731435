.dashboard {
    .card {
        margin: 15px 0;

        .card-content {
            padding: 5px 20px;

            .text-right {
                p {
                    line-height: 14px;
                    margin: 0px;
                    vertical-align: middle;
                    padding: 5px;
                }
            }

            .transaction-data-mode-switch {
                margin: 5px 0;
            }
        }
    }

    .row {
        .card.card-stats {
            .card-header {
                float: left;
                padding: 15px;
                box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);

                i {
                    vertical-align: middle;
                }
            }

            .card-content {
                .category {
                    height: 65px;
                    vertical-align: middle;
                    display: flex;
                    margin: auto;
                    align-items: center;
                    justify-content: right;
                    font-size: 18px;
                }

                .card-title {
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                }
            }

            .card-footer {
                .stats {
                    height: 40px;

                    .insight-1 {
                        background-color: #134b5a;
                    }

                    .insight-2 {
                        background: linear-gradient(222deg, #1e6381, #267a99 54%, #2e92b1);
                    }

                    .insight-3 {
                        background-color: #f0c41f;
                    }

                    .insight-4 {
                        background-color: #006272;
                    }

                    .material-icons {
                        // background-color: black;
                        color: white;
                    }

                    b {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}