@import './avgCharts';
@import './dataTable';
@import './pages';
@import './skeletonLoader';
@import './dashboardTable';
@import './home';
@import './dashboardDataPointsCards';
@import './abandonedGiftsLightbox';



.searchLogo {
  max-width: 100%;
  width: 160px;
  margin-right: 12px;
}

.hidden {
  display: none !important;
}

.clientLogo {
  width: 100%;
  height: auto;
  max-width: 144px;
  margin-left: 15px;
}

.loader {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: 'white';
}

.sidebar .logo .simple-text {
  text-transform: none !important;
}

.sidebar .user .photo {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  width: 12vw;
  height: auto;
  /* margin-bottom: 10px; */
  border-radius: 0 !important;
}

.settings-notice {
  padding: 10px;
  font-size: 13px;
}

.modal-backdrop {
  display: none !important;
}

sup {
  color: red;
}

.actionsMenu {
  button {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .table-striped i {
    padding-left: 0px !important;
  }

  i {
    padding-left: 0px !important;
  }
}

.editorText {
  min-height: 200px;
  border: 1px solid #cecece !important;
}

.ct-vertical {
  margin-left: 4px;
}

small {
  color: red;
}

//Reporting

.complex {
  sup {
    color: red;
  }

  h3 {
    display: inline-block;
  }

  select {
    height: 40px;
    font-size: 18px;
    min-width: 25%;
    margin-left: 10px;
  }

  #queryMenu i {
    padding-left: 0px !important;
  }
}

.scheduleQuery {
  select {
    width: 100%;
  }
}

.queryButtonPrimary:first-of-type {
  display: none;
}

.condition {
  box-shadow: none !important;
  width: 100%;
  margin: 15px;
}

.rule {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

//Nav

.sidebar .nav [data-toggle="collapse"]~div>ul>li>a {
  padding-left: 25px !important;
}

////Donors

#donorSearch {
  .card-title {
    font-size: 18px !important;
  }

  .card-icon {
    .material-icons {
      font-size: 35px;
      border: 1px solid #e5e5e5;
      border-radius: 50%;
      width: 90px;
      line-height: 80px;
      height: 90px;
    }
  }
}

.formBuilderButton {
  button {
    width: 100% !important;
  }
}

.control-label {
  font-size: 13px !important;
  color: #555 !important;
}

.email-control-label {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.dropzone {
  div {
    width: 100% !important;
    padding: 20px !important;
  }
}

#editor,
#editorCSS {
  height: 500px !important;
}

.mainLogoLogin {
  max-width: 70%;
}

textarea {
  min-height: 150px;
  padding-top: 10px;
  border: 1px solid #cecece !important;
}

.activebutton {
  background-color: #f44336;
  color: #fff !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);

  i {
    color: #fff !important;
  }
}

.sidebar .nav li>a:hover,
.sidebar .nav li>a:focus,
.off-canvas-sidebar .nav li>a:hover,
.off-canvas-sidebar .nav li>a:focus {
  background-color: #ab1d44 !important;
  color: white;

  i {
    color: #fff !important;
  }
}

.activebutton:hover {
  background-color: #f44336;
  color: #fff !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);

  i {
    color: #fff !important;
  }
}

#emailOptions {
  select {
    height: 35px;
    font-size: 18px;
    min-width: 45%;
    margin-left: 10px;
  }
}

.td-actions {
  button {
    margin: 4px !important;
  }
}

#settingsDonationForm .panel-heading {
  padding: 10px;
}

.sortable-table {
  .table {
    thead {
      th {
        cursor: pointer;
        position: relative;
        text-align: center;
        min-height: 80px;
        background-color: #337ab7;
        color: #fff;
        padding: 5px;
        line-height: 25px;
        height: 80px;
        border-bottom: 5px solid #ccc;
      }

      th.sort-desc,
      th.sort-asc {
        background-color: #e0914c;
        border-bottom: 5px solid #c70000;
      }
    }

    tbody td:first-child {
      background-color: #f9f9f9;
    }

    tbody tr:hover td {
      background-color: lighten(#e0914c, 30);
      color: #000;
    }

    tbody tr td {
      padding: 10px;
      background-color: #fff;
      border-top-color: #ddd;
      color: #222;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .search-box {
    padding: 10px 0px;
    float: right !important;

    input.search {
      width: 200px;
      margin-left: 10px;
      height: 30px;
      border: none;
      padding-left: 10px;
      -moz-border-radius: 15px;
      -webkit-border-radius: 15px;
      border-radius: 15px;
      border: 2px solid #c30;
    }
  }

  .pager {
    margin: 5px 0;

    >* {
      float: left;
    }

    select.page-select {
      width: 80px;
    }

    &:after {
      content: "";
      clear: both;
      display: block;
    }

    .SortableTblLabel {
      height: 30px;
      line-height: 30px;
    }
  }

  .desc {
    margin: 5px 0;
    text-align: right;
  }
}

.sortable-table .pager>* {
  float: left;
}

.table-striped {
  br {
    display: none !important;
  }

  i {
    padding-left: 10px;
  }
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.sortable-table .table thead th {
  background-color: #337ab7 !important;
  height: auto !important;
}

.sortable-table .table tbody tr td {
  text-align: center !important;
}

.sortable-table .pager select.page-select {
  margin: 0 20px !important;
}

.ruleGroup {
  display: block;
  clear: both;
  padding: 10px;
  margin: 10px;
  border: 1px solid gray;
}

@media (min-width: 768px) {}

.navbar>.container .navbar-brand,
.navbar>.container-fluid .navbar-brand {
  margin-left: 5px !important;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 300 !important;
  line-height: 1.5em !important;
  color: rgba(0, 0, 0, 0.85);
}

h1,
h2,
h3,
h4,
p,
.stats,
label,
select,
span {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 300 !important;
  line-height: 1.5em !important;
}

#headingThree>a>span.material-icons.arrow-up-down {
  font-family: "Material Icons" !important;
}

body {
  background-color: #eeeeee !important;
  color: #3c4858 !important;
}

h3,
.h3 {
  font-size: 1.825em !important;
  margin: 20px 0 10px !important;
}

.loginTitle {
  color: #000;
}

.navbar-absolute {
  position: absolute !important;
  width: 100% !important;
  padding-top: 10px !important;
  z-index: 1029 !important;
}

.pageType {
  border-radius: 12px;
}

.bootstrap-datetimepicker-widget {
  width: 340px !important;

  &.dropdown-menu {
    margin: 2px 0;
    padding: 4px;
    width: 19em;
  }
}

.queryButton:active {
  outline: none;
}

.queryButtonPrimary {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
  //color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  display: inline-block;
  position: relative;
  min-width: 64px;
  height: 36px;
  padding: 0 16px;
  border: none;
  border-radius: 2px;
  outline: none;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  -webkit-transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
  min-width: 88px;
  margin-left: 1rem;
  cursor: pointer;
  background-color: #3f51b5;
  //background-color: var(--mdc-theme-primary, #3f51b5);
  color: white;
  //color: var(--mdc-theme-text-primary-on-primary, white);
}

.queryButtonPrimary:active {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.queryButtonDelete:active {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.queryButtonDelete {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  position: relative;
  min-width: 64px;
  height: 36px;
  padding: 0 16px;
  border: none;
  border-radius: 2px;
  outline: none;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  -webkit-transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
  min-width: 88px;
  margin-left: 1rem;
  cursor: pointer;
  color: #ff4081;
  color: #ff4081;
  background-color: #ff4081;
  background-color: #ff4081;
  color: white;
  color: white;
}

.rule {
  display: flex;
  border-radius: 3px;
  color: #9e9e9e;
  background: white;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14),
    0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  margin-left: 1rem;
  padding: 0.5rem;
  margin-top: 0.2rem;
  width: 85%;
  -webkit-animation: fade 1s;
  animation: fade 1s;
  opacity: 1;
}

.condition {
  margin-left: 1rem;
  padding: 0.5rem;
  margin-top: 0.2rem;
  border-radius: 3px;
  color: #9e9e9e;
  background: white;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14),
    0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  width: 85%;
  -webkit-animation: fade 1s;
  animation: fade 1s;
  opacity: 1;
}

.querySelect {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1.75rem;
  color: rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.87);
  padding-left: 0;
  padding-right: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  max-width: calc(100% - 24px);
  height: 32px;
  -webkit-transition: border-bottom-color 150ms 0ms cubic-bezier(0.4, 0, 1, 1),
    background-color 150ms 0ms cubic-bezier(0.4, 0, 1, 1);
  transition: border-bottom-color 150ms 0ms cubic-bezier(0.4, 0, 1, 1),
    background-color 150ms 0ms cubic-bezier(0.4, 0, 1, 1);
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0;
  background: none;
  background-repeat: no-repeat;
  background-position: right center;
  //background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewB…oints%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
  font-family: Roboto, sans-serif;
  font-size: 0.936rem;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.04em;
}

.querySelect:focus {
  border-bottom-color: #3f51b5;
  border-bottom-color: var(--mdc-theme-primary, #3f51b5);
  outline: none;
  background-color: rgba(0, 0, 0, 0.06);
}

.queryInput {
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  border: none;
  background: none;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: border-bottom-color 180ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: border-bottom-color 180ms cubic-bezier(0.4, 0, 0.2, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 8px;
  padding-bottom: 6px;
}

.queryInput:focus {
  border-color: #3f51b5;
  border-color: var(--mdc-theme-primary, #3f51b5);
  outline: none;
}

.queryText {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  letter-spacing: 0.04em;
  display: inline-block;
  margin-bottom: 8px;
  will-change: opacity, transform, color;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: initial;
  -webkit-transition: none;
  transition: none;
}

@-webkit-keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.error {
  color: red;
}

//Carts

#carts,
#processor,
#subscriptionID {
  width: 100%;
}

.selectCartBox {
  border: 1px solid #999 !important;
  border-radius: 5px !important;
  background-image: none !important;
}

.swal-text {
  text-align: center;
}

.priceIcon {
  float: right;
}

.reactTableCart {
  p {
    font-weight: 500;
  }

  .-headerGroups {
    // font-size: 20px;
    // font-weight: 700;
    // background-color: #fff !important;
    display: none !important;
  }

  .-header {
    background-color: #337ab7 !important;
    padding: 5px;
    color: #fff;
    font-size: 1.25em;
    font-weight: 300;
  }

  .rt-tr-group {
    background-color: #fff !important;
  }

  .ReactTable {
    margin: 0px 0px 30px 0px;

    .rt-noData {
      display: inline-block;
      width: auto;
      position: absolute;
      left: 50%;
      top: 48%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background: rgba(255, 255, 255, 0.8);
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      z-index: 1;
      pointer-events: none;
      padding: 0;
      color: rgba(0, 0, 0, 0.5);
    }

    .rt-thead {

      .rt-th.-sort-asc,
      .rt-td.-sort-asc,
      .rt-th.-sort-desc,
      .rt-td.-sort-desc {
        box-shadow: none;
        -webkit-box-shadow: none;
      }

      .rt-th {
        position: relative;

        .priceIcon_up {
          position: absolute;
          right: 12px;
          top: -1px;
        }

        .priceIcon_down {
          position: absolute;
          right: 12px;
          bottom: -1px;
        }
      }
    }

    .rt-tbody {
      max-height: 400px;
    }
  }

  h3 {
    font-size: 22px !important;
    font-weight: 500 !important;
  }
}

.cartSet .form-control {
  border: 1px solid #ccc !important;
  background-image: none !important;
  padding: 0px 5px;
}

.error-catagory {
  color: red;
}

.peer_reporting {
  .chartist-tooltip {
    opacity: 0;
    position: absolute;
    margin: 80px 0 0 10px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
  }

  .chartist-tooltip-value {
    margin: auto;
    display: table;
  }

  .chartist-tooltip.tooltip-show {
    opacity: 1;
  }
}

.uk-profile {
  h3 {
    padding-left: 10px;
  }

  h5 {
    padding-left: 10px;
  }
}

.uk-contact {
  h5 {
    padding-left: 10px;
  }
}

.breadcrumbsText {
  a {
    color: #7c7f82;
  }

  a:hover {
    color: #7c7f82;
    text-decoration: none;
  }

  .ripple-container {
    display: none;
  }
}

.main-dashboard-chart {
  .changeViewOption {
    margin-top: -50px;
  }

  .ct-chart {
    .ct-legend {
      position: unset;
    }
  }

  div#colouredRoundedLineChart {
    margin-top: 25px;

    span.ct-label.ct-horizontal.ct-end {
      transform: rotate(-104deg);
      margin-top: 9px;
      font-size: 12px;
      width: 20px !important;
      height: 20px !important;
    }
  }

  .YAxixTitle {
    position: absolute;
    top: 200px;
    left: -15px;
    transform: rotate(90deg);
  }
}

.swal-button.crm-restore-submit {
  background-color: #d7d7d7 !important;
  color: #555 !important;
  box-shadow: none;
}

.swal-button.crm-restore-submit:focus {
  box-shadow: none;
}

.swal-button.crm-restore-calcel:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(43, 114, 165, .29);
}

#settingsDonationForm {
  #collapseFour {
    .payment_pocessors {
      .form-group {
        margin: 0px;
        padding: 0px;
      }
    }
  }

  .panel-heading {
    background: #eee;
  }
}

#frequencyModeArray {

  .form-group {
    margin-top: 0px;
  }

  .frequency-chk {
    margin-top: 5px;
    text-transform: capitalize;
  }

  small {
    color: black;
  }
}

.donation_form_payment_processor_warning {
  p {
    margin-bottom: unset;
    color: red;
  }
}

.dst-timezone {
  display: inline-block;
  width: 100%;
  margin: 15px 0;

  thead {
    background-color: #337ab7;
    color: #fff;
  }

  h3 {
    text-align: center;
    font-size: 20px !important;
  }

}

#creativeDetails {

  .thermoMeterColor1,
  .thermoMeterColor2 {
    margin: 0 5px;
  }
}

.donation-heading {
  text-align: center;
  font-size: 16px;
  color: #000;
  font-weight: 700;
}

.display_identify_donor {
  display: flex;
  margin-top: 10px;
  justify-content: center;

  label {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  input {
    margin: 0px 0 0;
    cursor: pointer;
  }

  span {
    padding: 0 5px;
  }
}

.error-show {
  color: red;
  font-weight: 400;
}

.identifyDonorText {
  text-align: center;

  input {
    width: 100%;
    height: 45px;
    margin-top: 10px;
    padding: 10px;
  }
}

.email_opt_in_radio {
  display: flex;
  margin-top: 10px;

  label {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  input {
    margin: 0px 0 0;
    cursor: pointer;
  }

  span {
    padding: 0 5px;
    color: #000;
  }
}

.sidebar .nav p,
.off-canvas-sidebar .nav p {
  margin: 5px 0 0 0 !important;
}

.dropzoneDiv {
  /* width: 200px;  */
  height: 200px;
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
}

.dropzoneDiv p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-43%, -50%);
  width: 100%;
}

.schemaRow {
  .btn-danger {
    padding: 12px 20px !important;
  }
}

.utm-leader-board-container .card .card-header {
  height: 65px;
  width: 290px;
}

.utm-leader-board-container .card .card-header-icon {
  background: #FFA038;
}

.utm-leader-board-container .card .card-header-icon .icon-title-text {
  font-size: 1.5em;
  display: block;
}

.utm-leader-board-container .card .card-header-icon .icon-title-small-text {
  font-size: 1em;
}

.utm-leader-board-container .card .card-content .form-group {
  color: black;
  margin: 0px;
}

.utm-leader-board-container .card .card-content .form-group label {
  font-size: 18px;
  color: black;
}

.utm-leader-board-container .card .card-content .ant-calendar-picker .ant-calendar-picker-input {
  border: none;
}

.utm-leader-board-container .card .card-content .ant-calendar-picker .ant-calendar-picker-input .ant-calendar-range-picker-input {
  height: 99%;
  text-align: center;
  background-color: transparent;
  border: 0;
  outline: 0;
}

.utm-leader-board-container .card .card-content .ant-calendar-picker .ant-calendar-picker-input .ant-calendar-picker-icon {
  left: 0;
  color: black;
}

.ant-calendar-picker-container .ant-calendar .ant-calendar-panel .ant-calendar-date-panel .ant-calendar-range-middle {
  padding: 0px;
  left: 26%;
}

.utm-leader-board-container .card .card-content .transaction-range-container {
  // display: flex;
  align-items: end;
  padding: 0px 20px;
}

.utm-leader-board-container .card .card-content .transaction-range-container .donation-buttons {
  padding-left: 0px;
}

.utm-leader-board-container .card .card-content .transaction-range-container .donation-buttons .searh-button .ant-btn-icon {
  margin-right: -30px;
}

.utm-leader-board-container .card .card-content .transaction-range-container {

  .donation-buttons {
    .searh-button {
      font-size: 16px;
      height: 40px;
      border-radius: 4px;
    }

    .search-results-button {
      font-size: 16px;
      height: 40px;
      border-radius: px;
    }
  }

  .sub-cards-block {
    .sub-dynamic-cards {
      .export-buttons {
        font-size: 14px !important;
        height: 40px !important;
        border-radius: 4px;
      }
    }
  }

  .ant-picker-input input {
    text-align: center;
  }

}
.dashboard .card-content {
  .ant-picker {
    .ant-picker-input input {
      text-align: center;
    }
  }
}

.utm-leader-board-container .card .card-content .transaction-range-container .donation-buttons .ant {
  padding-left: 0;
  // padding-top: 10px;
}

.utm-leader-board-container .card .card-content .transaction-range-container .advance_field {
  display: grid;
}

.utm-leader-board-container .card .card-content .transaction-range-container .btn {
  margin-right: 10px;
}

.utm-leader-board-container .card .card-content .transaction-range-container .ant-calendar-picker .ant-calendar-picker-input {
  border: none;
  padding: 6px 2px;
}

.utm-leader-board-container .card .card-content .transaction-range-container .ant-calendar-picker .ant-calendar-picker-input .ant-calendar-range-picker-input {
  height: 99%;
  text-align: center;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px;
  left: 26%;
}

.utm-leader-board-container .card .card-content .transaction-range-container .ant-calendar-picker .ant-calendar-picker-input .ant-calendar-picker-icon {
  left: 0;
  color: black;
}

.utm-leader-board-container .card .card-content .transaction-range-container .ant-calendar-picker .ant-calendar-picker-input:first-child {
  padding-left: 12px;
}

.utm-leader-board-container .card .card-content .transaction-range-container .daily-date-picker .ant-calendar-picker .ant-calendar-picker-input {
  border: none;
  padding: 6px 30px;
}

.utm-leader-board-container .card .card-content .transaction-range-container .daily-date-picker .ant-calendar-picker .ant-calendar-picker-icon {
  left: 0;
  color: black;
}

.utm-leader-board-container .card .card-content .transaction-range-container .btn-generate-export {
  background: #1D617F;
  color: white;
  text-transform: none !important;
  width: 145px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  justify-content: space-around;
}

.utm-leader-board-container .card .card-content .transaction-range-container .btn-generate-export .material-icons {
  right: 3px;
}

.utm-leader-board-container {
  .card {
    .card-content {
      .toggle-search {
        display: flex;
        gap: 10px;
        justify-content: space-between;
      }
    }
  }
}

.utm-leader-board-container {
  .card {
    // .content-block {
      // display: flex;
      // width: 100%;
      // flex-direction: row;
      // justify-content: space-around;

      // .card-content {
      //   width: 80%;
      // }

      .sub-cards-block {
        // display: flex;
        // align-items: center;
        // justify-content: end;
        width: 100%;
        padding-right: 0;

        .ant-card{
          margin-bottom: 15px;
          text-align: center;

          .material-icons{
            font-size: 40px;
            display: block;
            color: #661645;
          }
        }
        .export-buttons{
          background: #1D617F !important;
          color: white !important;
          text-transform: none !important;
          width: 100% !important;
          height: 36px !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          font-size: 1vw !important;
          justify-content: space-around !important;
        }

      }

    // }
  }
}
.container-fluid{
  .dashboard {
    .dasboard-hr {
      border: 0;
      height: 2px;
      background-color: rgba(85,85,85,.12);
      padding: 0;
      margin: 20px 0;
    }
  }
}

.utm-leader-board-container .card .card-content .transaction-range-container #pages-subPages-table{
  width: 100%;
  margin-top: 20px;

  .ReactTable .rt-tbody {
      .rt-noData {
        top: 46%;
        left: 45%;
      }
    }
}

.utm-leader-board-container {
  .card {
    .card-content {
      .transaction-range-container {
        .viewButton {
          .ant-btn {
            height: 40px;
          }
        }
      }
    }
  }

  width: 100%;
  margin-top: 20px;
}